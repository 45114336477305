import React from "react";
import { Helmet } from "react-helmet";
import CanaryIcon from "../../assets/icons/Canary";
import FacebookIcon from "../../assets/icons/Facebook";
import InstagramIcon from "../../assets/icons/Instagram";
import HeartIcon from "../../assets/kickstarter/heart-icon.png";
import FreedomSquares from "../../assets/kickstarter/living-room-with-rug.jpg";
import ModularRug from "../../assets/kickstarter/modular-rug.jpg";
import RecycleIcon from "../../assets/kickstarter/recycle-icon.png";
import WashableIcon from "../../assets/kickstarter/washable-icon.png";
import WashableRug from "../../assets/kickstarter/washable-rug.jpg";
import "./kickstarter.scss";

export const KickstarterPage: React.FC = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    return (
        <div id="kickstarter">
            <Helmet>
                <title>Canary | Freedom Squares launch</title>
                <meta property="og:url" content="https://www.canarylaunch.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Canary | Freedom Squares launch" />
                <meta property="og:image" content={FreedomSquares} />
                <meta property="og:image:alt" content="Freedom squares rug launch" />
                <meta
                    property="og:description"
                    content="Our latest modular rug is launching. Sign up below and get access to a 45% launch discount."
                />
                <meta property="og:site_name" content="The Canary Life" />
                <meta property="og:locale" content="en_US" />

                <meta name="twitter:url" content="https://www.canarylaunch.com" />
                <meta name="twitter:title" content="Canary | Freedom Squares launch" />
                <meta
                    name="twitter:description"
                    content="Our latest modular rug is launching. Sign up below and get access to a 45% launch discount."
                />
                <meta name="twitter:image" content={FreedomSquares} />
                <meta name="twitter:image:alt" content="Freedom squares rug launch" />
            </Helmet>

            <section className="section" id="hero">
                <div className="container">
                    <div className="header">
                        <h1 className="is-bold">
                            Freedom Squares<span>™</span>
                        </h1>
                        <p>
                            by <CanaryIcon />
                        </p>
                    </div>

                    <div className="klaviyo-form">
                        <div className="klaviyo_field_group">
                            <label htmlFor="k_id_email">
                                <p className="is-bold is-small">
                                    Our latest modular rug has launched.
                                </p>
                                <p className="is-bold">Get access to a 45% launch discount.</p>
                            </label>

                            <a
                                href="https://www.kickstarter.com/projects/canarylife/freedom-squares-modular-rugs-made-from-recycled-plastic"
                                className="button is-primary is-cta"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Buy now on Kickstarter
                            </a>
                        </div>
                    </div>
                    <div className="icons">
                        <img src={WashableIcon} alt="Machine Washable" />
                        <img src={RecycleIcon} alt="100% Recyclable" />
                        <img src={HeartIcon} alt="100% Healthy" />
                    </div>
                </div>
            </section>

            <section className="section" id="coming-soon">
                <div className="container">
                    <h2 className="is-medium has-text-centered">
                        Create your own shapes with Canary’s modular Freedom Squares
                    </h2>
                    <p className="has-text-centered">
                        Canary rugs add beauty, style, warmth and comfort to any private, public or
                        office space. Our modular designs give you the superpower to decorate your
                        space just the way you like it. Our rugs are designed with convenience in
                        mind, built to deliver uncompromising quality and can be recycled/reused to
                        make another Canary product.
                    </p>
                    <p className="has-text-centered">Good for you, great for the planet.</p>

                    {/* <Button className="p is-bold">Kickstarter</Button> */}
                </div>
            </section>

            <section className="section" id="rug-details">
                <div className="container">
                    <div className="columns is-marginless">
                        <div className="column with-image is-5 is-offset-1">
                            <img src={ModularRug} alt="Modular rug" />
                            <div className="usps">
                                <div>
                                    <h2 className="is-bold usp">Modular</h2>
                                </div>
                            </div>
                        </div>
                        <div className="column is-5 has-content-centered">
                            <div className="content">
                                <h2 className="is-bold">Canary's Unique Strips</h2>
                                <p>
                                    With a Canary rug, there’s no need to buy a pad or use messy
                                    tape or glue to keep it in place. Our Gecko Strips use the same
                                    micro-adhesion technology found in geckos' toes and adhere the
                                    rug to your floor without leaving marks or stains.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns is-marginless">
                        <div className="column is-5 is-offset-1 has-content-centered">
                            <div className="content">
                                <h2 className="is-bold">
                                    Convenience is king, washability is queen.
                                </h2>
                                <p>
                                    Whether you have kids, pets or you’re simply a red wine
                                    enthusiast, we think you’ll appreciate the ability to throw your
                                    rug in the washing machine. No need for rug and carpet cleaners,
                                    just a standard-sized washer and laundry detergent will do the
                                    trick.
                                </p>
                            </div>
                        </div>
                        <div className="column with-image is-5">
                            <img src={WashableRug} alt="Washable rug" />
                            <div className="usps right">
                                <div>
                                    <h2 className="is-bold usp">Machine washable</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="banner">
                <div className="container is-fullhd">
                    <div className="notification">
                        <h1 className="is-bold has-text-centered">
                            Good for you. Good for the planet.
                        </h1>
                    </div>
                </div>
            </section>

            <section className="section" id="learn-more">
                <div className="container">
                    <h2 className="is-bold has-text-centered">
                        Choose the Canary rug that fits you perfectly
                    </h2>
                    <h2 className="is-light has-text-centered">The options are endless</h2>
                    <p className="has-text-centered">
                        Mix, match, dream and create with Canary Life’s Freedom Squares. They come
                        in a variety of colors and patterns, adding a stylish twist to any home,
                        office or public space. It’s never been more hip to be square.
                    </p>
                    {/* <Button className="p is-bold">Learn more</Button> */}
                </div>
            </section>

            <footer className="footer" id="footer">
                <div className="container">
                    <div className="social-media">
                        <a
                            href="https://www.facebook.com/thecanarylife/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            href="https://www.instagram.com/thecanarylife/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InstagramIcon />
                        </a>
                    </div>
                    {/* <div className="address">
                        <p className="is-light has-text-centered">
                            1550 Bryant Street, San Francisco, CA 94103m
                        </p>
                    </div> */}
                </div>
            </footer>
        </div>
    );
};
