import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { KickstarterPage } from "../../pages/KickstarterPage/KickstarterPage";

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Route exact path="/" component={KickstarterPage} />
            <Redirect path="*" to="/" />
        </BrowserRouter>
    );
};

export default App;
