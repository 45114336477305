import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import App from "./containers/App/App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

ReactGA.initialize("UA-130693477-3");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactPixel.init("1417104965098892");
ReactPixel.pageView();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
