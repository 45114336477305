import * as React from "react";

const InstagramIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 32.23 32.23" width="1em" height="1em" {...props}>
            <path
                d="M7.88 16.11a8.24 8.24 0 118.23 8.24 8.25 8.25 0 01-8.23-8.24zM24.61 0h-17A7.64 7.64 0 000 7.62v17a7.65 7.65 0 007.62 7.62h17a7.64 7.64 0 007.62-7.62v-17A7.63 7.63 0 0024.61 0zm-17 1.9h17a5.73 5.73 0 015.72 5.72v17a5.73 5.73 0 01-5.72 5.72h-17a5.73 5.73 0 01-5.71-5.73v-17A5.73 5.73 0 017.62 1.9zm18.7 2.41A1.67 1.67 0 1028 6a1.67 1.67 0 00-1.67-1.67zm.19 11.8A10.4 10.4 0 1016.11 26.5 10.4 10.4 0 0026.5 16.11z"
                fill="#262626"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default InstagramIcon;
