import * as React from "react";

const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 15.34 32.8" width="1em" height="1em" {...props}>
            <path
                d="M10.13 9.82V7.19a1.38 1.38 0 011.44-1.57h3.68V0h-5.07c-5.64 0-6.9 4.16-6.9 6.87v3H0v6.57h3.31V32.8h6.57V16.39h4.86l.26-2.58.39-4z"
                fill="#262626"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default FacebookIcon;
