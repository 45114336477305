import * as React from "react";

const CanaryIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 176 40.4" width="1em" height="1em" {...props}>
            <path d="M7.1 15.64a8.57 8.57 0 008.9 8.91 7.69 7.69 0 006.46-3.63h8A15.44 15.44 0 0115.8 31.49a16.23 16.23 0 01-11-4.43A15.74 15.74 0 0115.74 0c6.51 0 13 4.38 14.73 10.78h-8a7.37 7.37 0 00-6.83-3.84 8.34 8.34 0 00-8.54 8.7zM57.39 1v3.67C55.26 1.6 51.52 0 47.47 0a13.93 13.93 0 00-11 5.12 15.14 15.14 0 00-3.78 10.62 16.88 16.88 0 003.68 10.78 13.86 13.86 0 0010.88 5c3.74 0 8-1.57 10.14-4.38v3.42h6.52V1zm-8.85 23.55A8.55 8.55 0 0139.78 16c0-5.18 3.63-9.13 8.86-9.07 5-.06 8.54 3.79 8.54 8.75s-3.52 8.92-8.64 8.87zM92.08 3.68A11.84 11.84 0 0083.65 0c-3.53 0-7.1 1.23-9 3.68V1h-6.53v29.53h7.1v-13.4c0-2.35-.08-5.48 1.2-7.51a6.15 6.15 0 015.33-2.68 5.77 5.77 0 015.1 2.45c1.23 2 1.23 4.91 1.23 7.21v13.93h7.09V15.21c0-3.79 0-7.47-2.34-10.67a6.17 6.17 0 00-.75-.86zM122.9 1v3.67C120.76 1.6 117 0 113 0a13.91 13.91 0 00-11 5.12 15.15 15.15 0 00-3.79 10.62 16.94 16.94 0 003.68 10.78 13.88 13.88 0 0010.89 5c3.74 0 8-1.57 10.14-4.38v3.42h6.51V1zM114 24.55a8.54 8.54 0 01-8.71-8.55c0-5.18 3.63-9.13 8.86-9.07 5-.06 8.54 3.79 8.54 8.75s-3.53 8.92-8.69 8.87zM167.94 1l-7.1 19-6.77-19c-5.91-1.27-11.52.78-14 3.9V1h-6.46v29.53h7.1V16.17a8.5 8.5 0 012.44-6.44A9.14 9.14 0 01149 7.39l7.84 21-5.44 12.01h4.22a5.18 5.18 0 004.76-3.13L176 1z" />
        </svg>
    );
};

export default CanaryIcon;
